// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body {\n    background-color: white;\n    font-family: sans-serif;\n    margin: 0;\n}\n\n.src-styles_container-2pRR5Vj {\n    /* display: grid;\n    grid-template-columns: 200px 1fr; */\n}\n\n@media screen and (max-device-width: 1280px) {\n    .src-styles_container-2pRR5Vj {\n        /* grid-template-columns: max-content 1fr; */\n    }\n}\n\n.src-styles_pageContent-1oYGmtb {\n    display: flex;\n    flex-direction: column;\n    padding: 0 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "src-styles_container-2pRR5Vj",
	"pageContent": "src-styles_pageContent-1oYGmtb"
};
module.exports = exports;
