// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-pokemon-types_normal-3flwRTD {\n    background-color: #a8a878;\n}\n\n.src-components-pokemon-types_fighting-3qXWKGV {\n    color: white;\n    background-color: #c03028;\n}\n\n.src-components-pokemon-types_flying-2mHL2vp {\n    background-color: #a890f0;\n}\n\n.src-components-pokemon-types_poison-gOoulNf {\n    color: white;\n    background-color: #a040a0;\n}\n\n.src-components-pokemon-types_ground-1oGPhJ9 {\n    background-color: #e0c068;\n}\n\n.src-components-pokemon-types_rock-aMIdZl4 {\n    background-color: #b8a038;\n}\n\n.src-components-pokemon-types_bug-2CQITGi {\n    background-color: #a8b820;\n}\n\n.src-components-pokemon-types_ghost-1PGvJUh {\n    color: white;\n    background-color: #705898;\n}\n\n.src-components-pokemon-types_steel-Xq5AEys {\n    background-color: #b8b8d0;\n}\n\n.src-components-pokemon-types_fire-2iDfIf- {\n    background-color: #f08030;\n}\n\n.src-components-pokemon-types_water-2k8BWqu {\n    background-color: #6890f0;\n}\n\n.src-components-pokemon-types_grass-6Qxjn4c {\n    background-color: #78c850;\n}\n\n.src-components-pokemon-types_electric-2rvnAs- {\n    background-color: #f8d030;\n}\n\n.src-components-pokemon-types_psychic-3W2eFYT {\n    background-color: #f85888;\n}\n\n.src-components-pokemon-types_ice-1oNUkqi {\n    background-color: #98d8d8;\n}\n\n.src-components-pokemon-types_dragon-2fhyVkU {\n    color: white;\n    background-color: #7038f8;\n}\n\n.src-components-pokemon-types_dark-AflA0aa {\n    color: white;\n    background-color: #705848;\n}\n\n.src-components-pokemon-types_fairy-2l7tqed {\n    background-color: #ee99ac;\n}\n", ""]);
// Exports
exports.locals = {
	"normal": "src-components-pokemon-types_normal-3flwRTD",
	"fighting": "src-components-pokemon-types_fighting-3qXWKGV",
	"flying": "src-components-pokemon-types_flying-2mHL2vp",
	"poison": "src-components-pokemon-types_poison-gOoulNf",
	"ground": "src-components-pokemon-types_ground-1oGPhJ9",
	"rock": "src-components-pokemon-types_rock-aMIdZl4",
	"bug": "src-components-pokemon-types_bug-2CQITGi",
	"ghost": "src-components-pokemon-types_ghost-1PGvJUh",
	"steel": "src-components-pokemon-types_steel-Xq5AEys",
	"fire": "src-components-pokemon-types_fire-2iDfIf-",
	"water": "src-components-pokemon-types_water-2k8BWqu",
	"grass": "src-components-pokemon-types_grass-6Qxjn4c",
	"electric": "src-components-pokemon-types_electric-2rvnAs-",
	"psychic": "src-components-pokemon-types_psychic-3W2eFYT",
	"ice": "src-components-pokemon-types_ice-1oNUkqi",
	"dragon": "src-components-pokemon-types_dragon-2fhyVkU",
	"dark": "src-components-pokemon-types_dark-AflA0aa",
	"fairy": "src-components-pokemon-types_fairy-2l7tqed"
};
module.exports = exports;
