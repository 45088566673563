// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-nav-styles_navContainer-eMrA2H7 {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 10px;\n    background: rgb(187, 187, 187);\n    background: linear-gradient(\n        0deg,\n        rgba(255, 255, 255, 1) 0%,\n        rgba(255, 255, 255, 1) 10%,\n        rgba(187, 187, 187, 1) 100%\n    );\n    height: 90vh;\n}\n\n.src-components-nav-styles_navLink-aOUlrXq {\n    margin-bottom: 8px;\n    text-decoration: none;\n    color: black;\n}\n\n.src-components-nav-styles_activeNavLink-3uXJYM9 {\n    font-weight: bold;\n}\n\n.src-components-nav-styles_navIcon-1cHG4zV {\n    display: inline;\n    margin-right: 5px;\n}\n\n/* Phone */\n@media screen and (max-device-width: 1280px) {\n    .src-components-nav-styles_navIcon-1cHG4zV {\n        font-size: 28px;\n    }\n\n    .src-components-nav-styles_navIcon-1cHG4zV:last-of-type {\n        margin-right: 0;\n    }\n\n    .src-components-nav-styles_navText-1hPSw28 {\n        display: none;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"navContainer": "src-components-nav-styles_navContainer-eMrA2H7",
	"navLink": "src-components-nav-styles_navLink-aOUlrXq",
	"activeNavLink": "src-components-nav-styles_activeNavLink-3uXJYM9",
	"navIcon": "src-components-nav-styles_navIcon-1cHG4zV",
	"navText": "src-components-nav-styles_navText-1hPSw28"
};
module.exports = exports;
