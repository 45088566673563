// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-header-styles_container-239-S6A {\n    font-weight: bold;\n    font-size: 18px;\n    margin-bottom: 10px;\n}\n\n/* Phone */\n@media screen and (max-device-width: 1280px) {\n    .src-components-header-styles_container-239-S6A {\n        display: none;\n        width: 35px;\n        font-weight: normal;\n        font-size: 12px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"container": "src-components-header-styles_container-239-S6A"
};
module.exports = exports;
